import React, { useState, useEffect } from "react";
import SharedNumbersInfo from "./sharedSlideInfo";
import { motion, useScroll, useTransform } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";
import styles from "./style.module.scss";
const list = [
    {
        title: "Connect With the Right Customers at the Right Time",
        tabName: "Research",
        slug: "research",
        description:
            "The research on outsourcing your lead generation is in, and the results are clear. According to CustomerThink, businesses that outsource are 43% more likely to succeed. We excel in lead generation across several industries, and here is why.",
        img: "/severalbrands/assets/images/homePageConnectImg.jpg",
        bgColor: "#E6D4FF",
    },
    {
        title: "Legal",
        tabName: "Legal",
        slug: "legal",
        description:
            "85% of law firms struggle with generating new business, according to the American Bar Association. The legal jargon and intimidating communication with lawyers can be off-putting for potential clients. Our lead generation company specializes in creating straightforward, informative content and ads that boost client confidence. We target the right people and have a track record of bringing signed retainers to our legal network with very low fallout rates.",
        img: "/severalbrands/assets/images/homePageLegalImg.jpg",
        bgColor: "#D4BBFF",
    },
    {
        title: "B2B",
        tabName: "B2B",
        slug: "b2b",
        description:
            "Marketing Donut found that a staggering 80% of leads are left unattended and never followed up on, a costly mistake that can lead to many missed opportunities. Our high-tech system allows us to qualify genuine leads and nurture potential customers. Whether it's a follow-up text message or an email from our diverse marketing campaigns, we know how to provide you with high-quality prospective clients that you can turn into loyal customers.",
        img: "/severalbrands/assets/images/homePageB2BImg.jpg",
        bgColor: "#C2A1FF",
    },
    {
        title: "B2C",
        tabName: "B2C",
        slug: "b2c",
        description:
            "According to MarketingSherpa, 74% of B2C businesses prioritize converting leads into paying customers. With so many options available to consumers and the risk of scams and fraud, it can be challenging to achieve high conversion rates. Our carefully curated content on our review and comparison websites ensures customers find precisely what they want without compromising quality through organic search (SEO). Our targeted ads on search engines (SEM) and social media bring customers directly into contact with what they seek.",
        img: "/severalbrands/assets/images/homePageB2CImg2.jpg",
        bgColor: "#B081FF",
    },
];
const scaleFrom = 1;
const translateFrom = 800;
export default function B2BSlides() {
    const windowSize = useWindowSize();
    const [selectedEl, setSelectedEl] = useState("legal");
    const { scrollY } = useScroll();
    const [limit, setLimit] = useState(0);
    const scaleValue1 = useTransform(
        scrollY,
        [limit, limit + 1200],
        [scaleFrom, 0.97],
    );
    const scaleValue2 = useTransform(
        scrollY,
        [limit + 1200, limit + 2400],
        [scaleFrom, 0.98],
    );
    const scaleValue3 = useTransform(
        scrollY,
        [limit + 2400, limit + 3600],
        [scaleFrom, 0.99],
    );

    const yValue2 = useTransform(
        scrollY,
        [limit, limit + 1200],
        [translateFrom, windowSize.width > 2024 ? -610 : -460],
    );
    const yValue3 = useTransform(
        scrollY,
        [limit + 1200, limit + 2400],
        [translateFrom, windowSize.width > 2024 ? -1220 : -920],
    );
    const yValue4 = useTransform(
        scrollY,
        [limit + 2400, limit + 3600],
        [translateFrom, windowSize.width > 2024 ? -1830 : -1380],
    );

    const filteredList =
        windowSize.width < 1024
            ? [list.find((el) => el.slug === selectedEl)]
            : [...list];
    const finalList = filteredList.map((el, index) => {
        if (index === 0) {
            return {
                ...el,
                zIndex: 1,
                scaleValue: scaleValue1,
                yValue: 0,
            };
        }
        if (index === 1) {
            return {
                ...el,
                zIndex: 2,
                scaleValue: scaleValue2,
                yValue: yValue2,
            };
        }
        if (index === 2) {
            return {
                ...el,
                zIndex: 3,
                scaleValue: scaleValue3,
                yValue: yValue3,
            };
        }
        if (index === 3) {
            return {
                ...el,
                zIndex: 4,
                scaleValue: 1,
                yValue: yValue4,
            };
        }
    });

    const [hadFixedStyle, setFixedStyle] = useState(false);
    const [afterStyle, setAfterStyle] = useState(false);
    useEffect(() => {
        const limit =
            document
                .getElementById("mainScrollDivHomePage")!
                .getBoundingClientRect().top +
            window.scrollY +
            48;
        setLimit(limit);

        const handleScroll = () => {
            if (window.scrollY < limit + 3800 && window.scrollY > limit) {
                setFixedStyle(true);
            } else {
                setFixedStyle(false);
            }
            if (window.scrollY > 7000) {
                setAfterStyle(true);
            } else {
                setAfterStyle(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div id="mainScrollDivHomePage">
            <section
                className={`${
                    hadFixedStyle && windowSize.width > 1024
                        ? styles["fixed"]
                        : ""
                } ${styles["bgStyleBefore"]}    ${
                    afterStyle && windowSize.width > 1024
                        ? styles["bgStyleAfter"]
                        : ""
                }`}
            >
                <div
                    className={`${
                        hadFixedStyle && windowSize.width > 1024
                            ? "max-w-7xl 3xl:max-w-[120rem] mx-auto block px-4vw lg:px-4  "
                            : ""
                    } `}
                >
                    <h2 className="font-bold   text-3xl  leading-9 text-[#37367D] lg:hidden">
                        Connect With the Right Customers at the Right Time
                    </h2>
                    <p className="mt-5 font-light lg:hidden mb-14">
                        The research on outsourcing your lead generation is in,
                        and the results are clear. According to CustomerThink,
                        businesses that outsource are 43% more likely to
                        succeed. We excel in lead generation across several
                        industries, and here is why.
                    </p>
                    <div className="flex justify-center gap-10  lg:hidden">
                        {list
                            .filter((slide) => slide.slug !== "research")
                            .map((el) => (
                                <motion.button
                                    whileHover={{
                                        scale: [1, 1.2, 1],
                                        transition: {
                                            duration: 0.8,
                                            repeat: Infinity,
                                            delay: 0.3,
                                        },
                                    }}
                                    key={el.slug}
                                    className={`block text-[#6340A4] lg:text-lg lg:px-7 px-4 lg:py-2 py-1 cursor-pointer  ${
                                        el.slug === selectedEl
                                            ? "border border-[#6340A4] rounded-3xl"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setSelectedEl(el.slug);
                                    }}
                                >
                                    {el.tabName}
                                </motion.button>
                            ))}
                    </div>
                    <ul className="mt-7 lg:mt-0    ">
                        {finalList?.map((el, index) => (
                            <SharedNumbersInfo key={index} info={el} />
                        ))}
                    </ul>
                </div>
            </section>
        </div>
    );
}
